<template>
	<div :style="bg">
		<Header :title="$t('setting.changePayPwd')"></Header>
		
		<div class="column-center-content main-content">
			<div class="info-content">
				<v-text-field :placeholder="$t('setting.newPayPwd_hint')" color="success" v-model="newPwd" type="password">
					<v-icon style="width: 20px; margin-right: 10px; color:#FF0000;" slot="prepend" >
						fa fa-lock
					</v-icon>
				</v-text-field>
				<v-text-field :placeholder="$t('setting.newPayPwdConfirm_hint')" color="success" v-model="newPwdConfirm" type="password">
					<v-icon style="width: 20px; margin-right: 10px; color:#FF0000;" slot="prepend" >
						fa fa-lock
					</v-icon>
				</v-text-field>
				<div class="row-content">
					<v-text-field :placeholder="$t('login.tac_hint')" color="success" v-model="code">
						<v-icon style="width: 20px; margin-right: 10px; color:#FF0000;" slot="prepend" >
							fa fa-envelope-open-o
						</v-icon>
					</v-text-field>
					<mt-button @click="sendCode" type="danger" plain style="height: 30px; border-radius: 15px; font-size: 10px;">{{$t('login.getTac')}}</mt-button>
				</div>
			</div>
			
			<div class="btn-content">
				<v-btn rounded block @click="submitChange" style="background-color: #009245; color: white; height: 40px;">
					{{$t('common.submit')}}
				</v-btn>
			</div>
		</div>
	</div>
</template>

<script>
	import "@/styles/common.css"
	import { Indicator } from 'mint-ui';
	import { Toast } from 'mint-ui';
	import Header from '../../../components/NavHeader.vue'
	
	export default {
		name: 'ChangePayPwd',
		components: {
			Header
		},
		data() { 
			return {
				bg : {
					height: "100vh",
					width: "100%",
					backgroundImage: "url(" + require("../../../assets/login/new-bg.png") + ")",
					backgroundRepeat: "no-repeat",
					backgroundPosition: "fixed",
					backgroundSize: "100% 100%",
					display: "flex",
					flexDirection: "column",
					alignItems: "center",
					overflow: "auto"
				},
				newPwd: '',
				newPwdConfirm: '',
				code: ''
				
			}
		},
		methods: {
			
			sendCode() {
				Indicator.open({
					text: '',
					spinnerType: 'fading-circle'
				});
				
				let phone = JSON.parse(localStorage.getItem('userInfo')).phone;
				let params = {
					phone: phone
				}
				this.$request.sendCode(
					params
				).then(()=>{
					Indicator.close();
				});
			},
			
			submitChange() {
								
				let pwd = this.$data.newPwd;
				let confirmPwd = this.$data.newPwdConfirm;
				let code = this.$data.code;
				
				if(null == pwd || '' == pwd) {
					Toast({
						message: this.$i18n.t("setting.newPayPwd_hint"),
						position: 'bottom'
					});
					return;
				}
				if(null == confirmPwd || '' == confirmPwd) {
					Toast({
						message: this.$i18n.t("setting.newPayPwdConfirm_hint"),
						position: 'bottom'
					});
					return;
				}
				if(pwd != confirmPwd) {
					Toast({
						message: this.$i18n.t("login.pwdConfirm_error"),
						position: 'bottom'
					});
					return;
				}
				if(null == code || '' == code) {
					Toast({
						message: this.$i18n.t("login.tac_hint"),
						position: 'bottom'
					});
					return;
				}
			
				let params = {
					account: JSON.parse(localStorage.getItem('userInfo')).account,
					code: code,
					newPassword: pwd,
					phone: JSON.parse(localStorage.getItem('userInfo')).phone
				}
					
				Indicator.open({
					text: '',
					spinnerType: 'fading-circle'
				});			
				this.$request.setPayPwd(
					params
				).then(()=>{
					Indicator.close();
					this.$router.go(-1)
				});
			}
		}
	}
</script>

<style>
</style>
